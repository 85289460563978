/* test */
.hello-bg {
  background-color: #ffcd0f;
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  height: 300vh;
  width: 300vh;
  border-radius: 10000px;
  animation: bubble .75s ease-in-out forwards;
  animation-delay: 1.75s;
  z-index: 80;
}

@keyframes bubble {
  0% {
    top: 50%;
    right: 50%;
    height: 300vh;
    width: 300vh;
  }

  100% {
    right: 0;
    top: 0;
    height: 0px;
    width: 0px;
  }
}

.hello {
  color: black;
  font-weight: 900;
  font-size: 72px;
  position: fixed;
  z-index: 81;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: hello_kf 2s ease-out forwards;
}

@keyframes hello_kf {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
  80%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

}


.container {
  height: 100vh;
  width: 100vw;
  display: block;
  position: absolute;
  background-color: transparent;
}

.container div.description {
  color: #ffcd0f;
  white-space: normal;
  opacity: 0;
  font-size: 2rem;
  text-align: right;
  position: absolute;
  bottom: 35%;
  max-width: 600px;
  right: 25%;
  height: auto;
  transition: all 1s ease-in-out;
  transition-delay: 0s;
}

.container div.description.display {
  opacity: 1;
  transition-delay: 0.5s;
}



a.mail {
  position: absolute;
  right: 25%;
  bottom: 20%;
  transform: translate(0%, -50%);

  border: 2px solid #ffcd0f;
  border-radius: 1000px;
  padding: 20px 50px;

  font-style: normal;
  color: #ffcd0f;
  text-decoration: none;

  transition: all .5s ease-in-out;
}

a.mail:hover {
  color: black;
  background-color: #ffcd0f;
}

footer {
  color: #ffcd0f;
  white-space: nowrap;
  text-align: center;
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -10px);
  padding: 10px 50px;
  border-radius: 100px;
  background-color: transparent;
}

logo {
  color: #ffcd0f;
  font-weight: bold;
  font-family: 'Poiret One', cursive;
  font-size: 6rem;
  position: absolute;
  top: 10%;
  left: 20%;
  scale: 1;
}


@media only screen and (max-width: 1024px) {
  .container div.description {
    opacity: 0;
    font-size: 1.3rem;
    text-align: right;
    position: absolute;
    bottom: 45%;
    width: auto;
    left: 10%;
    right: 10%;
    transition: all 1s ease-in-out;
  }

  logo {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poiret One', cursive;
    font-size: 6rem;
  }

  a.mail {
    right: 50%;
    bottom: 25%;
    transform: translate(50%, 0%);
  }

}
