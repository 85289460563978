* {
  font-size: 18px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

@media only screen and (max-width: 1024px) {
  * {
    font-size: 14px;
  }
}